import dynamic from "next/dynamic";
import { FC, Fragment } from "react";
import { useRecoilValue } from "recoil";

import { megaMenuAtom } from "~/services/megaMenu";
import { NodesResponse } from "~/data/home/interface";

import { cloneDeep } from "lodash";

const MenuItem = dynamic(() => import("~/components/Items/MenuItem"), {
  ssr: true,
});
export const buildTree = (nodes: NodesResponse[]) => {
  const tree = cloneDeep(nodes);
  const map = new Map();
  tree.forEach((node) => map.set(node.node_id, node));
  tree.forEach((node) => {
    const parent = map.get(node.parent_id);
    if (parent) {
      if (!parent.children) {
        parent.children = [];
      }
      parent.children.push(node);
    }
  });
  return tree.filter((node) => node.level === 0);
};

export interface NavigationProps {
  menu?: NodesResponse[];
}

const Navigation: FC<NavigationProps> = ({ menu: megaMenu }) => {
  const { menu } = useRecoilValue(megaMenuAtom.megaMenu);
  return (
    <div className="w-full max-w-[1328px] mx-auto px-4 items-center gap-x-4 gap-y-1 flex-wrap hidden lg:flex">
      {(megaMenu ?? menu)?.map((mItem: any, index) => (
        <Fragment key={`${mItem.node_id}-${index}`}>
          <MenuItem data={mItem} index={index} />
        </Fragment>
      ))}
    </div>
  );
};

export default Navigation;
