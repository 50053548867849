import axios from 'axios';
import { getFromCookie, hashEmail } from '~/utils/helper';
import { getLocalStorage } from '~/utils/local-storage';
import { Cart } from '../cart';
import { Order } from '../order';
import { Product, ProductVariant } from '../product';

export async function FBApiInitiateCheckout({ cartInfo }: { cartInfo: Cart }) {
    const response = await fetch('/api/get-ip');
    const { ip } = await response.json();
    const params = {
        data: JSON.stringify([
            {
                event_name: 'InitiateCheckout',
                event_time: Math.floor(Date.now() / 1000),
                event_source_url: process.env.NEXT_PUBLIC_URL,
                user_data: {
                    em: hashEmail(cartInfo?.email as string), // Hashed email
                    ph: hashEmail(cartInfo?.shipping_addresses?.[0]?.telephone + ''), // Hashed phone number,
                    external_id: cartInfo?.cartId,
                    fbp: getFromCookie('_fbp') ?? undefined,
                    fbc: getFromCookie('fbclid') ?? undefined,
                    client_user_agent: navigator.userAgent,
                    client_ip_address: ip ?? undefined
                },
                custom_data: {
                    currency: cartInfo?.prices?.grand_total?.currency,
                    value: cartInfo?.prices?.grand_total?.value
                },
                event_id: cartInfo.cartId
            }
        ])
    };

    const fbs = getLocalStorage('facebookPixel');
    if (fbs?.length > 0) {
        fbs.map((fb: any) => {
            const url = `https://graph.facebook.com/v20.0/${fb?.code}/events`;
            // Example event data
            const access_token = fb?.accessToken;
            try {
                axios.post(url, null, {
                    params: {
                        ...params,
                        access_token
                    }
                });
            } catch (error: any) {
                console.error('Error:', error.response ? error.response.data : error.message);
            }
        });
    }
}

export async function FBApiPurchase({ order }: { order: Partial<Order> }) {
    const response = await fetch('/api/get-ip');
    const { ip } = await response.json();
    // Example event data
    const params = {
        data: JSON.stringify([
            {
                event_name: 'Purchase',
                event_time: Math.floor(Date.now() / 1000),
                event_source_url: process.env.NEXT_PUBLIC_URL,
                user_data: {
                    em: hashEmail(order?.email as string), // Hashed email
                    ph: hashEmail(order?.shipping_addresses?.[0]?.telephone + ''), // Hashed phone number
                    external_id: order?.cartId,
                    fbp: getFromCookie('_fbp') ?? undefined,
                    fbc: getFromCookie('_fbc') ?? undefined,
                    client_user_agent: navigator.userAgent,
                    client_ip_address: ip ?? undefined
                },
                custom_data: {
                    currency: order?.prices?.grand_total?.currency,
                    value: order?.prices?.grand_total?.value,
                    content_type: 'product',
                    contents: order?.items?.map((item: any) => ({
                        id: item.product?.sku, // SKU của sản phẩm
                        quantity: item.product?.quantity // Số lượng
                    })),

                    num_items: order.items?.length // Số sản phẩm trong đơn hàng
                },
                event_id: order.cartId
            }
        ])
        // test_event_code: 'TEST41520',
        // access_token: accessToken
    };

    // Send the event to Facebook Conversions API using Axios
    const fbs = getLocalStorage('facebookPixel');
    if (fbs?.length > 0) {
        fbs.map((fb: any) => {
            const url = `https://graph.facebook.com/v20.0/${fb?.code}/events`;
            // Example event data

            const access_token = fb?.accessToken;
            try {
                axios.post(url, null, {
                    params: {
                        ...params,
                        access_token
                    }
                });
            } catch (error: any) {
                console.error('Error:', error.response ? error.response.data : error.message);
            }
        });
    }
}

export async function FBApiAddToCart(payload: {
    variant: Partial<ProductVariant>;
    product: Partial<Product>;
    qty: number;
    cartId: string;
}) {
    const { cartId, product, qty } = payload || {};
    const response = await fetch('/api/get-ip');
    const { ip } = await response.json();
    // Example event data
    const params = {
        data: JSON.stringify([
            {
                event_name: 'AddToCart',
                event_time: Math.floor(Date.now() / 1000),
                event_source_url: process.env.NEXT_PUBLIC_URL,
                user_data: {
                    // em: hashEmail(order?.email as string), // Hashed email
                    // ph: hashEmail(order?.shipping_addresses?.[0]?.telephone + ''), // Hashed phone number
                    external_id: cartId,
                    fbp: getFromCookie('_fbp') ?? undefined,
                    fbc: getFromCookie('_fbc') ?? undefined,
                    client_user_agent: navigator.userAgent,
                    client_ip_address: ip ?? undefined
                },
                custom_data: {
                    currency: product?.price_range?.minimum_price?.final_price?.currency,
                    value: product?.price_range?.minimum_price?.final_price?.value,
                    content_type: 'product',
                    contents: [
                        {
                            id: product?.sku, // SKU của sản phẩm
                            quantity: qty // Số lượng
                        }
                    ]
                },
                event_id: cartId
            }
        ])
        // test_event_code: 'TEST41520',
        // access_token: accessToken
    };

    // Send the event to Facebook Conversions API using Axios
    const fbs = getLocalStorage('facebookPixel');
    if (fbs?.length > 0) {
        fbs.map((fb: any) => {
            const url = `https://graph.facebook.com/v20.0/${fb?.code}/events`;
            // Example event data

            const access_token = fb?.accessToken;
            try {
                axios.post(url, null, {
                    params: {
                        ...params,
                        access_token
                    }
                });
            } catch (error: any) {
                console.error('Error:', error.response ? error.response.data : error.message);
            }
        });
    }
}

// export function FBPixelProduct({ product, variant }: { product: Partial<Product>; variant: Partial<ProductVariant> }) {
//     import('react-facebook-pixel')
//         .then((x) => x.default)
//         .then((ReactPixel) => {
//             ReactPixel.track('ViewContent', {
//                 content_type: 'product',
//                 content_ids: [product?.sku],
//                 value: product?.price_range?.minimum_price?.final_price?.value,
//                 content_name: product?.name,
//                 currency: product?.price_range?.minimum_price?.final_price?.currency
//             });
//         });
// }
