import { FC, ReactNode, useCallback, useEffect, useRef } from 'react';

import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { CartAtom } from '~/services/cart';

import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_CUSTOMER_DATA_GQL } from '~/data/app-layout/gql';
import { User, UserAtom } from '~/services/user';

import { isEmpty, omit } from 'lodash';
import Head from 'next/head';
import { toast } from 'react-toastify';
import { GUEST_CART_ID } from '~/constants/localstore';
import { GET_AVAILABLE_COUNTRIES, useCart } from '~/data/cart';
import { GET_STORE_GQL, GET_WISH_LIST, QUERY_GET_STORE_CONFIG_GQL } from '~/data/home';
import { QUERY_GET_MEGA_MENU_GQL } from '~/data/mega-menu/gql';
import { buildTree } from '~/sections/Navigation/Navigation';
import { CountriesAtom } from '~/services/countries';
import { megaMenuAtom } from '~/services/megaMenu';
import storeConfigAtom from '~/services/store-config/store-config.atom';
import { trackingSignIn } from '~/services/tracking';
import { WishListAtom } from '~/services/wishlist';
import { getLocalStorage, LocalStorageKey, removeLocalStorage, setLocalStorage } from '~/utils/local-storage';
import { transformNodesToMenu } from '~/utils/transform';
import dynamic from 'next/dynamic';
import TicketDiscount from '~/assets/svgs/ticket-discount.svg';
import React from 'react';
import Colors from '~/constants/colors';
import ProgressDiscount from '~/components/ProgressDiscount';
import { AlertAtom } from '~/services/alerts';
import { Identifiers } from '~/constants/enum';
interface LayoutProps {
    children?: ReactNode;
}

const RemindProductIntoCart = dynamic(() => import('~/components/RemindProductIntoCart'), {
    ssr: false,
    loading: () => null
});

const Alert = dynamic(() => import('~/components/Alert'), {
    ssr: false,
    loading: () => null
});

const AppLayout: FC<LayoutProps> = ({ children }) => {
    const { getCartInfo, createEmptyCart, mergeCart, applyCouponCode, removeCouponCode } = useCart();
    const setAlert = useSetRecoilState(AlertAtom.alerts);
    const resetCurrentUser = useResetRecoilState(UserAtom.currentUser);
    const resetCountries = useResetRecoilState(CountriesAtom.countries);
    const resetCart = useResetRecoilState(CartAtom.cart);
    const setCart = useSetRecoilState(CartAtom.cart);
    const cart = useRecoilValue(CartAtom.cart);

    const setStoreConfig = useSetRecoilState(storeConfigAtom.store);
    const setWishList = useSetRecoilState(WishListAtom.wishlist);
    const setCountries = useSetRecoilState(CountriesAtom.countries);
    const setMegaMenu = useSetRecoilState(megaMenuAtom.megaMenu);

    const [currentUser, setCurrentUser] = useRecoilState(UserAtom.currentUser);

    const { data: storeConfig } = useQuery(QUERY_GET_STORE_CONFIG_GQL);

    const { data: store } = useQuery(GET_STORE_GQL);

    // init
    const [getCustomer] = useLazyQuery(GET_CUSTOMER_DATA_GQL, {
        fetchPolicy: 'network-only'
    });
    const [getWishList]: any = useLazyQuery(GET_WISH_LIST);

    const { data: dataCountries } = useQuery(GET_AVAILABLE_COUNTRIES);

    const { data: dataMegaMenu } = useQuery(QUERY_GET_MEGA_MENU_GQL, {
        variables: {
            identifiers: Identifiers.MainMenu,
        },
        context: { fetchOptions: { method: 'GET' } }
    });

    const pathname = usePathname();
    const router = useRouter();

    const discount = router.query.discount as string;
    const cartSelector = useRecoilValue(CartAtom.cartSelector);
    const limitRequestCartId = useRef<number>(0);
    const isCheckout = pathname?.includes('/checkout');

    const handleGetCart = useCallback(
        async (cartId: string) => {
            setCart((prev) => ({
                ...prev,
                cartId
            }));
            return await getCartInfo({
                variables: {
                    cartId
                },
                onError: (errors) => {
                    resetCart();
                    if (limitRequestCartId.current <= 5) {
                        limitRequestCartId.current += 1;
                        createEmptyCart({
                            onCompleted(data, clientOptions) {
                                const createEmptyCart = data?.createEmptyCart;
                                localStorage.setItem(GUEST_CART_ID, createEmptyCart);
                                handleGetCart(createEmptyCart);
                            }
                        });
                    }
                }
            });
        },
        [createEmptyCart, getCartInfo, setCart]
    );

    const logout = useCallback(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('expired-time');
        resetCurrentUser();
        const timeOut = setTimeout(() => {
            window.location.reload();
            clearTimeout(timeOut);
        }, 100);
        if (pathname !== '/') {
            router.push('/');
        }
    }, []);

    const firstRenderInit = useRef(false);

    useEffect(() => {
        setStoreConfig((prev) => ({
            ...prev,
            ...(storeConfig?.storeConfig || {}),
            availableStores: store?.availableStores
            // relatedRules: dataRelatedRules?.getRelatedRules,
        }));
    }, [storeConfig, store]);

    useEffect(() => {
        if (typeof window === 'undefined') return;
        const token = localStorage.getItem('token');
        if (!!token || firstRenderInit.current === true) {
            return;
        }
        const cartId = localStorage.getItem(GUEST_CART_ID);
        firstRenderInit.current = true;
        if (!!cartId) {
            handleGetCart(cartId);
        } else {
            if (limitRequestCartId.current <= 5) {
                limitRequestCartId.current += 1;
                createEmptyCart({
                    onCompleted(data, clientOptions) {
                        const createEmptyCart = data?.createEmptyCart;
                        localStorage.setItem(GUEST_CART_ID, createEmptyCart);
                        // resetCountries()
                        handleGetCart(createEmptyCart);
                        // saveCartInfoAction(data.createEmptyCart);
                    }
                });
            }
        }
    }, [createEmptyCart, handleGetCart]);

    const firstSyncCart = useRef(false);

    useEffect(() => {
        if (typeof window === 'undefined') return;
        const token = localStorage.getItem('token');
        if (token && firstSyncCart.current === false) {
            firstSyncCart.current = true;
            setCurrentUser((prev) => ({
                ...prev,
                token,
                loading: true
            }));
            getWishList({
                onCompleted(data: any) {
                    setWishList(data.customer?.wishlist);
                }
            });
            getCustomer({
                onError(error) {
                    logout();
                },
                onCompleted(data) {
                    const user = data?.customer as User;
                    trackingSignIn({ ...(data?.customer || {}) });
                    setCurrentUser((prev) => ({
                        ...prev,
                        ...user,
                        token,
                        loading: false
                    }));
                }
            });
            createEmptyCart({
                onCompleted(data) {
                    const customerCartId = data?.createEmptyCart;
                    const guestCartId = localStorage.getItem(GUEST_CART_ID);
                    if (!!guestCartId) {
                        mergeCart({
                            variables: {
                                guestCartId,
                                customerCartId
                            },
                            onCompleted(dataMerge) {
                                localStorage.removeItem(GUEST_CART_ID);
                                handleGetCart(dataMerge.mergeCarts.id);
                            }
                        });
                    } else {
                        handleGetCart(customerCartId);
                    }
                }
            });
        }
    }, [createEmptyCart, currentUser?.token, getCustomer, handleGetCart, logout, mergeCart, setCurrentUser]);

    const initCountries = useRef(false);
    useEffect(() => {
        if (!!dataCountries?.countries?.length && initCountries.current === false) {
            setCountries((prev) => {
                return {
                    ...prev,
                    countries: dataCountries.countries
                };
            });
            initCountries.current = true;
        }
    }, [dataCountries]);

    useEffect(() => {
        const handleResize = () => {
            setStoreConfig((prev) => ({
                ...prev,
                windowWidth: window.innerWidth
            }));
        };
        setStoreConfig((prev) => ({
            ...prev,
            windowWidth: window.innerWidth
        }));
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (typeof window !== undefined) {
            const _learnq = window ? window['_learnq'] : undefined;
            const cartData = cartSelector;

            if (
                storeConfig?.storeConfig?.klaviyo_enable &&
                !!cartData?.email &&
                !!_learnq &&
                !!_learnq.isIdentified &&
                !_learnq.isIdentified()
            ) {
                const firstShipment = cartData?.shipping_addresses && cartData?.shipping_addresses[0];
                const firstName = firstShipment?.firstname || '';
                const lastname = firstShipment?.lastname || '';
                _learnq.push([
                    'identify',
                    {
                        $email: cartData.email,
                        $first_name: firstName,
                        $last_name: lastname
                    }
                ]);
            }
        }
    }, [cartSelector, storeConfig?.storeConfig?.klaviyo_enable]);

    useEffect(() => {
        if (!isEmpty(dataMegaMenu?.getMenuByIdentifier?.nodes)) {
            setMegaMenu((prev) => ({
                ...prev,
                menu: transformNodesToMenu(buildTree(dataMegaMenu?.getMenuByIdentifier?.nodes || []))
            }));
        }
    }, [dataMegaMenu?.getMenuByIdentifier?.nodes]);

    // useEffect(() => {
    //   if (isCheckout && !checkout.cartId) {
    //     setCheckout(cartSelector);
    //   }
    // }, [cartSelector, cartSelector.cartId, checkout.cartId, isCheckout, setCheckout])

    const checkCartRef = useRef(false);
    const onRemoveQueryDiscount = useCallback(() => {
        const newQuery = omit(router.query, 'discount');
        router.replace(
            {
                pathname: router.pathname,
                query: newQuery
            },
            undefined,
            { shallow: true }
        );
    }, [router.query]);

    useEffect(() => {
        if (!discount) return;

        setLocalStorage(LocalStorageKey.DISCOUNT_CODE, discount);
        onRemoveQueryDiscount();
    }, [discount, cart.applied_coupons]);

    useEffect(() => {
        const discountLocalStorage = getLocalStorage(LocalStorageKey.DISCOUNT_CODE);
        if (checkCartRef.current) return;
        if (isEmpty(cart.items)) return;
        if (!discountLocalStorage) return;
        checkCartRef.current = true;
        removeLocalStorage(LocalStorageKey.DISCOUNT_CODE);

        if (isEmpty(cart.applied_coupons)) {
            applyCouponCode({
                variables: {
                    cartId: cart.cartId,
                    couponCode: discountLocalStorage as string
                }
            }).then((result) => {
                if (!result?.errors) {
                    // onToastApplyCoupon({ coupon: discountLocalStorage as string });
                    setAlert((prev) => ({
                        ...prev,
                        message: `That's great! 🎉 You've successfully applied the <b class="text-primary">${discountLocalStorage}</b> coupon code`,
                        open: true
                    }));
                }
            });
            return;
        }

        if (cart.applied_coupons?.[0]?.code !== discountLocalStorage) {
            setCart((prev) => ({
                ...prev,
                loadingCheckingRemoveDiscount: true
            }));
            removeCouponCode({
                variables: {
                    cartId: cart.cartId
                }
            })
                .then(() => {
                    applyCouponCode({
                        variables: {
                            cartId: cart.cartId,
                            couponCode: discountLocalStorage as string
                        }
                    }).then((result) => {
                        if (!result?.errors) {
                            // onToastApplyCoupon({ coupon: discountLocalStorage as string });
                            setAlert((prev) => ({
                                ...prev,
                                message: `That's great! 🎉 You've successfully applied the <b class="text-primary">${discountLocalStorage}</b> coupon code`,
                                open: true
                            }));
                        }
                    });
                })
                .catch((error) => {
                    console.log('Error Remove Coupon', error);
                })
                .finally(() => {
                    setCart((prev) => ({
                        ...prev,
                        loadingCheckingRemoveDiscount: false
                    }));
                });
            return;
        }
    }, [cart.items]);
    const initAlertCoupon = useRef(false);
    useEffect(() => {
        if (!isEmpty(cart?.applied_coupons) && !initAlertCoupon.current) {
            setAlert((prev) => ({
                ...prev,
                message: `That's great! 🎉 coupon <b class="text-primary">${cart?.applied_coupons?.[0]?.code}</b> applied! Complete your order to enjoy the discount.`,
                open: true
            }));
            initAlertCoupon.current = true;
        }
    }, [cart?.applied_coupons]);
    return (
        <React.Fragment>
            <Head>
                {' '}
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
            </Head>
            <RemindProductIntoCart />
            {children}
        </React.Fragment>
    );
};

export default AppLayout;

const onToastApplyCoupon = ({ coupon }: { coupon: string }) => {
    if (!coupon) return;
    toast.success(
        <div>
            Discount Code <b>{coupon}</b> has been applied!
        </div>,
        {
            autoClose: 3000,
            style: {
                padding: 0
            },
            bodyStyle: {
                padding: 10
            },
            icon: <TicketDiscount size={20} color={Colors.ACCENT_GREEN_50} />,
            closeButton: false,
            className: 'md:w-[400px] md:right-2 !overflow-hidden'
        }
    );
};
